<template>
    <div class="buyer-information">
        <div class="buyer-information-item">
            <h4>{{ $t('buyerInfo.title') }}</h4>

            <CRButton class="mb-16" v-if="user" @click="setAccountInformation" pattern="thin">
                {{ $t('buttons.setAccountInformation') }}
            </CRButton>

            <CRInput
                v-model="v$.userData.firstName.$model"
                class="input"
                required
                :label="$t('common.name')"
                :name="'given-name'"
                :placeholder="$t('placeholder.enterYourName')"
                :errors="v$.userData.firstName.$errors"
                is-vuelidate
            />
            <CRInput
                v-model="v$.userData.lastName.$model"
                class="input"
                required
                :name="'family-name'"
                :label="$t('common.surname')"
                :placeholder="$t('placeholder.enterSurname')"
                :errors="v$.userData.lastName.$errors"
                is-vuelidate
            />
            <CRInput
                v-model="v$.userData.companyName.$model"
                class="input"
                :name="'business_name'"
                :label="$t('common.companyName')"
                :placeholder="$t('placeholder.enterCompanyName')"
                :errors="v$.userData.companyName.$errors"
                is-vuelidate
            />
        </div>

        <div class="buyer-information-item">
            <h4>{{ $t('buyerInfo.contacts') }}</h4>
            <CRInput
                v-model="v$.userData.phone.$model"
                class="input"
                required
                :type="'tel'"
                :name="'phone'"
                :label="$t('common.phoneNr')"
                :placeholder="$t('placeholder.enterPhoneNumber')"
                :errors="v$.userData.phone.$errors"
                is-vuelidate
            />
            <CRInput
                v-model="v$.userData.email.$model"
                class="input mb-16"
                required
                :name="'email'"
                :label="$t('common.email')"
                :placeholder="$t('placeholder.enterEmail')"
                :errors="v$.userData.email.$errors"
                is-vuelidate
            />
            <!-- <CRCheckbox v-if="!user" v-model:checked="createAccount">
                {{ $t('common.createAccount') }}
            </CRCheckbox> -->
        </div>

        <div class="buyer-information-item">
            <h4>{{ $t('buyerInfo.deliveryAddress') }}</h4>
            <div class="btn__wrapper" v-if="user?.addresses?.length">
                <CRTag
                    class="btn"
                    v-for="address in user.addresses"
                    :key="address.id"
                    @click="selectAddress(address)"
                    icon="location"
                    :type="selectedAddress?.id === address.id ? 'primary' : 'additional'"
                    edit-allowed
                    :svgColor="selectedAddress?.id === address.id ? '#fff' : '#00595A'"
                    @edit="openEditModal(address)"
                >
                    {{ address.name }}
                </CRTag>
            </div>

            <AddressForm @onFormChange="onAddressFormChange" :address-errors="addressErrors" :address="selectedAddress">
                <template #footer="{ address }">
                    <div v-if="user" class="description">
                        {{ $t('buyerInfo.youCanSaveAddress') }} -
                        <CRButton class="btn" pattern="thin" @click="saveAddress(address)">
                            {{ $t('buttons.save') }}
                        </CRButton>
                    </div>
                </template>
            </AddressForm>
        </div>

        <slot name="btn-section" :user-data="userData" :address-data="addressData" :city-data="addressData.city" />

        <EditAddressModal
            v-if="isEditAddressModalOpen"
            @close="closeEditModal"
            @destroyed="closeEditModalOnDestroy"
            @updated="closeEditModalOnUpdate"
            :address="editedAddress"
        />
    </div>
</template>

<script>
    import CRInput from '@/components/Common/Inputs/CRInput.vue';
    import CRTag from '@/components/Common/Tags/CRTag.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import EditAddressModal from '@/components/Modals/EditAddressModal.vue';
    import AddressForm from '@/components/Forms/AddressForm.vue';
    import { mapActions, mapGetters } from 'vuex';
    import { INITIAL_ADDRESS_DATA } from '@/components/Forms/AddressForm.vue';
    import { cloneDeep } from 'lodash';
    import { useVuelidate } from '@vuelidate/core';
    import { required, email, helpers, minLength, maxLength } from '@vuelidate/validators';

    export default {
        name: 'BuyerInformation',
        components: { CRInput, CRButton, CRTag, EditAddressModal, AddressForm },
        props: {
            buyerInformation: {
                type: Object,
                default: null,
            },
        },
        setup() {
            return { v$: useVuelidate() };
        },
        data() {
            return {
                userData: {
                    firstName: '',
                    lastName: '',
                    companyName: '',
                    phone: '',
                    email: '',
                },
                addressData: { ...INITIAL_ADDRESS_DATA },
                createAccount: false,

                // edit address
                selectedAddress: null,
                isEditAddressModalOpen: false,
                editedAddress: null,

                addressErrors: {},
            };
        },
        validations() {
            return {
                userData: {
                    firstName: {
                        required: helpers.withMessage(this.$t('errors.required'), required),
                        minLength: helpers.withMessage(
                            ({ $params }) => this.$t('errors.minLength', { amount: $params.min }),
                            minLength(1)
                        ),
                        maxLength: helpers.withMessage(
                            ({ $params }) => this.$t('errors.maxLength', { amount: $params.max }),
                            maxLength(100)
                        ),
                    },
                    lastName: {
                        required: helpers.withMessage(this.$t('errors.required'), required),
                        minLength: helpers.withMessage(
                            ({ $params }) => this.$t('errors.minLength', { amount: $params.min }),
                            minLength(1)
                        ),
                        maxLength: helpers.withMessage(
                            ({ $params }) => this.$t('errors.maxLength', { amount: $params.max }),
                            maxLength(100)
                        ),
                    },
                    companyName: {
                        minLength: helpers.withMessage(
                            ({ $params }) => this.$t('errors.minLength', { amount: $params.min }),
                            minLength(1)
                        ),
                        maxLength: helpers.withMessage(
                            ({ $params }) => this.$t('errors.maxLength', { amount: $params.max }),
                            maxLength(100)
                        ),
                    },
                    phone: {
                        required: helpers.withMessage(this.$t('errors.required'), required),
                        minLength: helpers.withMessage(
                            ({ $params }) => this.$t('errors.minLength', { amount: $params.min }),
                            minLength(9)
                        ),
                        phone: helpers.withMessage(
                            this.$t('errors.phone'),
                            helpers.regex(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/)
                        ),
                    },
                    email: {
                        required: helpers.withMessage(this.$t('errors.required'), required),
                        email: helpers.withMessage(this.$t('errors.email'), email),
                    },
                },
            };
        },
        computed: {
            ...mapGetters('auth', ['user']),
            ...mapGetters('city', ['selectedCity']),
        },
        methods: {
            ...mapActions('auth', ['storeAddress']),

            setDataIfExistInState() {
                if (this.buyerInformation) {
                    this.userData = { ...this.buyerInformation.userData };
                    this.addressData = { ...this.buyerInformation.addressData };
                    this.selectedAddress = { ...this.buyerInformation.addressData };
                }
            },

            setAccountInformation() {
                this.userData = {
                    firstName: this.user.firstName,
                    lastName: this.user.lastName,
                    companyName: this.user.companyName ?? '',
                    phone: this.user.phone ?? '',
                    email: this.user.email,
                };
            },

            onAddressFormChange(data) {
                this.addressData = data;
            },

            selectAddress(address) {
                this.selectedAddress = address;
                this.addressData = address;
            },

            // edit address
            openEditModal(address) {
                this.editedAddress = address;
                this.isEditAddressModalOpen = true;
            },

            closeEditModalOnDestroy() {
                this.addressData = { ...INITIAL_ADDRESS_DATA };
                this.addressData.city = this.selectedCity;

                this.selectedAddress = { ...INITIAL_ADDRESS_DATA };
                this.selectedAddress.city = this.selectedCity;
            },

            closeEditModalOnUpdate() {
                if (this.selectedAddress?.id === this.editedAddress.id) {
                    const address = this.user.addresses.find((address) => address.id === this.editedAddress.id);
                    this.selectedAddress = address;
                    this.addressData = address;
                }
            },

            closeEditModal() {
                this.editedAddress = null;
                this.isEditAddressModalOpen = false;
            },

            // save address
            async saveAddress(address) {
                try {
                    let addressCopy = cloneDeep(address);

                    addressCopy.name = `${this.$t('common.address')} ${this.user.addresses.length + 1}`;
                    addressCopy.cityId = addressCopy.city.id;

                    delete addressCopy.city;

                    await this.storeAddress(addressCopy);

                    this.selectedAddress = this.user.addresses.slice(-1).pop();
                    this.addressData = this.user.addresses.slice(-1).pop();
                } catch (error) {
                    this.addressErrors = error.errors;
                }
            },
        },

        mounted() {
            this.$nextTick(() => {
                this.addressData.city = this.selectedCity;
                this.setDataIfExistInState();
            });
        },
    };
</script>

<style lang="scss" scoped>
    .buyer-information {
        width: 100%;

        .buyer-information-item {
            margin-bottom: 32px;

            h4 {
                margin-bottom: 24px;
            }

            .input {
                margin-bottom: 24px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            .btn__wrapper {
                @include row-align-center;
                flex-wrap: wrap;

                margin-bottom: 18px;

                .btn {
                    cursor: pointer;
                    margin-bottom: 10px;

                    margin-right: 8px;
                }

                .btn:last-child {
                    margin-right: 0;
                }
            }

            .description {
                margin-top: 16px;

                .btn {
                    display: inline;
                }
            }
        }
    }
</style>
