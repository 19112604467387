import { validateForm } from '@/helpers/ValidationRulesHelper';

export const checkIsFormValid = (userData, addressData) => {
    const userDataCopy = { ...userData };
    const addressDataCopy = { ...addressData };
    delete userDataCopy.companyName;
    delete userDataCopy?.userId;
    delete addressDataCopy?.name;
    delete addressDataCopy?.apartmentNumber;
    delete addressDataCopy?.floor;
    delete addressDataCopy?.postalCode;
    delete addressDataCopy?.extraInformation;

    return !(validateForm(userDataCopy).isValid && validateForm(addressDataCopy).isValid);
};
