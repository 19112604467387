<template>
    <BuyerInformation is-for-check-out :buyer-information="buyerInformation">
        <template #btn-section="{ userData, addressData, cityData }">
            <div class="btn-section">
                <CRButton @click="$router.push({ name: 'checkOutDelivery' })" pattern="outline">
                    {{ $t('buttons.back') }}
                </CRButton>
                <CRButton @click="toPaymentPage(userData, addressData, cityData)">
                    <!-- {{ $t('common.payment') }} -->
                    {{ $t('common.delivery') }}
                </CRButton>
            </div>
        </template>
    </BuyerInformation>
</template>

<script>
    import BuyerInformation from '@/components/Order/BuyerInformation.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import { checkIsFormValid } from '@/helpers/BuyerInfoHelper';
    import { mapActions, mapGetters } from 'vuex';
    import { useVuelidate } from '@vuelidate/core';
    import { ConversionEventNameEnum } from '@/utils/enums';
    

    export default {
        name: 'BuyerInfo',
        components: { BuyerInformation, CRButton },
        setup() {
            return { v$: useVuelidate() };
        },
        data() {
            return {
                checkIsFormValid,
            };
        },
        computed: {
            ...mapGetters('orderInformation', ['buyerInformation']),
        },
        methods: {
            ...mapActions('orderInformation', ['setBuyerInformation']),

            async toPaymentPage(userData, addressData, cityData) {
                const result = await this.v$.$validate();
                if (!result) {
                    this.$filters.toast(this.$t('errors.someFieldsAreNotFilledCorrectly'));
                    return;
                }

                this.setBuyerInformation({ userData, addressData, cityData });

                fbq('track', ConversionEventNameEnum.ADD_PAYMENT_INFO);

                this.$router.push({ name: 'checkOutDelivery' });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .btn-section {
        @include row-align-center-justify-between;
    }
</style>
